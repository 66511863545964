<template>
  <div class="container-fluid m-0 p-3">
    <div class="row m-0 p-4   shadow   bg-white rounded">
      <div class="col-12 col-lg-6">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend input-group-sm">
            <select class="custom-select" v-model="data.mode">
              <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                {{ e.name }}
              </option>
            </select>
          </div>

          <input
            type="search"
            class="form-control"
            placeholder="ค้นหา / Seach"
            v-model="data.find"
            v-on:keyup.enter="getuserole()"
          />
        </div>
      </div>
      <!-- ----------------------- -->
      <div class="col-12 col-lg-6">
        <div class="row form-inline mb-3">
          <div class="col-2 text-right">หมวดหมู่</div>
          <div class="col-10">
            <div class="input-group input-group-sm ">
              <input
                type="text"
                class="form-control"
                v-model="selectnow"
                @click="CallModalfindbycatcode()"
              />
              <div class="input-group-append">
                <button class="input-group-text" @click="Clearcatcode()">
                  x
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- -------------- -->
      <div class="col-12 col-lg-6">
        <div class="row form-inline mb-3">
          <div class="col-6 col-xl-2 text-right">Enrich</div>
          <div class="col-6 col-xl-10  ">
            <select
              class="form-control form-control-sm w-100"
              v-model="data.enrich"
            >
              <option value="">
                เเสดงทั้งหมด
              </option>
              <option v-for="(e, i) in enrich" :key="i" :value="e.name">
                {{ `${e.detail}(${e.name})` }}
              </option>
            </select>
            <!-- <div
                  class="form-check form-check-inline"
                  v-for="(e, i) in enrich"
                  :key="i"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :value="e.key"
                    v-model="data.enrich"
                  />
                  <label class="form-check-label">{{ e.name }}</label>
                </div> -->
          </div>
        </div>
      </div>
      <!-- ---------------------- -->
      <div class="col-12">
        <button
          type="button"
          class="btn bt-main btn-sm mx-1 px-4"
          @click="confirm()"
        >
          <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
        </button>
        <button
          type="button"
          class="btn bt-main1 btn-sm mx-1 px-4"
          @click="redata()"
        >
          <span class="mx-1" v-html="$svg_icon.reset"></span>รีเซ็ต
        </button>
      </div>
    </div>
    <!-- ---------- table --------------- -->
    <div class="row mx-0 my-3 p-4   shadow   bg-white rounded">
      <p class="px-3 font-600 w-100" style="display: flex">
        สินค้า&nbsp;
        <span class="text-main">{{
          new Intl.NumberFormat().format(totalRows)
        }}</span>
        &nbsp;รายการ
        <!-- <span
          v-if="report === true"
          style="flex:9; text-align: right; white-space: nowrap;"
          ><button class="btn btn-sm bt-DC" @click="exportReport()">
            นำออกรายงานการปฏิเสธ <span v-html="$svg_icon.excel"></span></button
        ></span>
        <span style="flex: 1; text-align: right; white-space: nowrap;"
          ><button class="btn btn-sm bt-bnb" @click="$emit('export_excel')">
            นำออกข้อมูล <span v-html="$svg_icon.excel"></span></button
        ></span> -->
      </p>
      <!-- ------------------ -->
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          hover
          outlined
          show-empty
          responsive
          :busy="$store.getters['enrichproductActions/get_onload']"
          class="font-0-8s"
        >
          <template #table-busy>
            <div class="text-center text-main my-2">
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(Active)="row">
            <span
              :class="[row.item.Active == 'Yes' ? 'badge-main' : 'badge-twd']"
              class="badge"
              >{{ row.item.Active }}</span
            >
          </template>

          <template #cell(enrich)="row">
            <!-- {{ row.item }} -->
            <span
              v-if="
                $serviceMain.chechnull(String(row.item.enrich).trim()) == ''
              "
            >
            </span>

            <span v-else>
              <p class="badge   font-500 badge-main m-0 py-1  ">
                {{ row.item.enrich }}
              </p>
            </span>
          </template>
          <template #cell(action)="row">
            <span
              v-html="$svg_icon.edit"
              v-if="row.item.enrich !== 'A'"
              type="button"
              class="mr-3 text-muted"
              v-b-tooltip.hover="'Write Content'"
              @click="edit(row.item)"
            ></span>

            <span
              v-html="$svg_icon.view"
              type="button"
              class="text-main-h"
              v-b-tooltip.hover="'View'"
              @click="view(row.item)"
            ></span>
          </template>
        </b-table>
      </div>
      <!-- ----------------- -->
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="data.RowsOfPage">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="data.PageNumber"
              :total-rows="totalRows"
              :per-page="data.RowsOfPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <Modalfindbycatcode />
  </div>
</template>

<script>
import Modalfindbycatcode from "@/components/Products/EnrichProduct/Modalfindbycatcode.vue";
import { get_enrich_qr, get_pim_product_qr } from "@/api/Product";
export default {
  components: { Modalfindbycatcode },
  data() {
    return {
      totalRows: 0,
      reModalfindbycatcode: Date.now(),
      selectnow: this.$store.getters["categoryActions/get_category"].selectnow,
      data: {
        mode: "skCode",
        find: "",
        lvl: 1,
        excel: "N",
        catcode: "",
        showonweb: ["''PWB''"],
        showonwebconvert: "",
        enrich: "",
        newproductnoly: false,
        editbydate: null,
        approvedbydate: null,
        writecontent: true,
        RowsOfPage: 25,
        PageNumber: 1,
      },
      page_size_list: [25, 50, 75, 100],
      enrich: [],
      select_mode: [
        { name: "ค้นหาชื่อสินค้า", key: "prNameTH" },
        { name: "ค้นหาด้วยเลข SKCode", key: "skCode" },
        { name: "ค้นหาด้วย Brand", key: "brand" },
      ],

      fields: [
        { key: "skCode", label: "skCode" },
        { key: "catCode", label: "catCode" },
        { key: "catName", label: "Sub-Dept Name", thStyle: { width: "10%" } },
        { key: "prNameTH", label: "prNameTH" },

        { key: "brand", label: "brand​", class: "text-center" },

        { key: "lastUpdate", label: "lastUpdate" },
        { key: "updateBy", label: "updateBy" },
        {
          key: "Active",
          label: "Active",
          class: "text-center",
          thStyle: { width: "8%" },
        },
        {
          key: "enrich",
          label: "enrich",
          class: "text-center",
          thStyle: { width: "8%" },
        },

        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "8%" },
        },
      ],
      items: [],
    };
  },
  watch: {
    com_RowsOfPage: function(v) {
      this.confirm();
    },
    com_PageNumber: function(v) {
      this.confirm();
    },
    data: {
      async handler(val) {
        await this.$store.dispatch("enrichproductActions/setdata_qr", val);
      },
      deep: true,
    },
    getcategory: function(v) {
      this.selectnow = v.selectnow;
      this.lvl = v.lvl;
      this.catcode = v.catcode;
    },
  },
  computed: {
    getcategory: function() {
      return this.$store.getters["categoryActions/get_category"];
    },
    com_RowsOfPage: function() {
      return this.$store.getters["enrichproductActions/get_data_qr"].RowsOfPage;
    },
    com_PageNumber: function() {
      return this.$store.getters["enrichproductActions/get_data_qr"].PageNumber;
    },
  },
  mounted() {
    this.init();
    this.call_get_enrich_qr();
    this.confirm();
  },
  methods: {
    async view(val) {
      let routeData = this.$router.resolve({
        name: "ProductInfo-ProductQR-Preview",
        params: { skcode: val.skCode },
      });
      window.open(routeData.href, "_blank");
    },
    edit(val) {
      let { skCode } = val;
      this.$router.push({
        name: "ProductInfo-ProductQR-Content",
        params: { skcode: skCode },
      });
    },
    init() {
      let data = this.$store.getters["enrichproductActions/get_data_qr"];
      const {
        mode,
        find,
        lvl,
        excel,
        catcode,
        showonweb,
        showonwebconvert,
        enrich,
        newproductnoly,
        editbydate,
        approvedbydate,
        writecontent,
        RowsOfPage,
        PageNumber,
      } = data;

      this.data.mode = mode;
      this.data.find = find;
      this.data.lvl = lvl;
      this.data.excel = excel;
      this.data.catcode = catcode;
      this.data.showonweb = showonweb;
      this.data.showonwebconvert = showonwebconvert;
      this.data.enrich = enrich;
      this.data.newproductnoly = newproductnoly;
      this.data.editbydate = editbydate;
      this.data.approvedbydate = approvedbydate;
      this.data.writecontent = writecontent;
      this.data.RowsOfPage = RowsOfPage;
      this.data.PageNumber = PageNumber;
    },
    async confirm() {
      try {
        let data = this.$store.getters["enrichproductActions/get_data_qr"];
        data.lvl = this.$store.getters["categoryActions/get_category"].lvl;
        data.catcode = this.$store.getters[
          "categoryActions/get_category"
        ].catcode;

        let getData = await get_pim_product_qr(data);

        // console.log(getData.data);
        this.items = getData.data;
        this.totalRows = getData?.data[0]?.total || 0;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data = {
        mode: "skCode",
        find: "",
        lvl: 1,
        excel: "N",
        catcode: "",
        showonweb: ["''PWB''"],
        showonwebconvert: "",
        enrich: "",
        newproductnoly: false,
        editbydate: null,
        approvedbydate: null,
        writecontent: true,
        RowsOfPage: 25,
        PageNumber: 1,
      };
      this.$store.dispatch("categoryActions/resetcategory");
    },
    async call_get_enrich_qr() {
      try {
        this.enrich = [];
        let getdata = await get_enrich_qr();
        this.enrich = getdata.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcode");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategory");
    },
  },
};
</script>

<style></style>
